import React from "react";
import {
  Route,
  Routes
} from "react-router-dom";
import Home from "./Components/Home";
import Email from "./Components/Modale/Email";
import Otp from "./Components/Modale/Otp";
import Login from "./Components/Modale/Login";
import User from "./Components/User/User";
import AuthUser from "./AuthUser";
import AuthHeader from "./Components/Pages/AuthHeader";
import Header from './Components/Pages/Header'
import Sidebar from './Components/Pages/Sidebar'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Project from "./Components/Project";
import About from "./Components/About";
import Privacy from "./Components/Privacy";
import Template from "./Components/Template";
import WhiteBoard from "./Components/Design/WhiteBoard";
export default function Guest() {
  const {http} = AuthUser();
  const {token,user} = AuthUser();

  const {getToken} = AuthUser();

   return (
    <div>
       <ToastContainer />
       { getToken() ? ( 
      <AuthHeader />
      ) : (
      <Header />
      )}
    <Sidebar/>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/profile" element={<User />} />
      <Route path="/project" element={<Project />} />
      <Route path="/about" element={<About />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/template" element={<Template />} />
      <Route path="/white-board" element={<WhiteBoard />} />
    </Routes>
      <Email/>
      <Otp/>
      <Login/>
    </div>

  );
}
