

import React from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Template() {
  return (
    <>
    <section className="main-sec">
      <div className="editor-bannr">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8">
              <div className="editor-bannr-bg">
                <div className="customize-icon">
                  <div className="customize-icon-lft">
                    <strong>Page 1</strong> Add Title
                  </div>
                  <div className="customize-icon-rgt">
                    <a href="javascript:void(0);">
                      <i className="fas fa-chevron-up" />
                    </a>
                    <a href="javascript:void(0);">
                      <i className="fas fa-chevron-down" />
                    </a>
                    <a href="javascript:void(0);">
                      <i className="fas fa-trash-alt" />
                    </a>
                    <a href="javascript:void(0);">
                      <i className="fal fa-plus-square" />
                    </a>
                    <a href="javascript:void(0);">
                      <i className="far fa-lock-alt" />
                    </a>
                  </div>
                </div>
                <div className="editor-bannr-innr">
                  <img
                    src="assets/images/editor-banner-02.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="editor-bannr-bg d-lg-none d-md-none ">
                <div className="customize-icon">
                  <div className="customize-icon-lft">
                    <strong>Page 1</strong> Add Title
                  </div>
                  <div className="customize-icon-rgt">
                    <a href="javascript:void(0);">
                      <i className="fas fa-chevron-up" />
                    </a>
                    <a href="javascript:void(0);">
                      <i className="fas fa-chevron-down" />
                    </a>
                    <a href="javascript:void(0);">
                      <i className="fas fa-trash-alt" />
                    </a>
                    <a href="javascript:void(0);">
                      <i className="fal fa-plus-square" />
                    </a>
                    <a href="javascript:void(0);">
                      <i className="far fa-lock-alt" />
                    </a>
                  </div>
                </div>
                <div className="editor-bannr-innr">
                  <img
                    src="assets/images/editor-banner.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  
  
  
  )
}
