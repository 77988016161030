

import React from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function WhiteBoard() {
  return (
    <section className="main-sec">
    <div className="white-board">
      <div className="board-header">
        <a href="javascript:void(0);">Position</a>
      </div>
    </div>
  </section>
  
  
  

  )
}
