import React from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Header() {
  return (
    <header className="header">
        <ToastContainer />
  <div className="row align-items-center">
    <div className="col-lg-8 col-2">
      <div className="row">
        <div className="col-lg-1">
          <div className="home-content">
            <a href="javascript:void(0);">
              <i className="bx bx-menu" />
            </a>
          </div>
        </div>
        <div className="col-lg-11">
          <nav className="navbar navbar-expand-lg main-menu">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    <i className="fal fa-home me-2" />
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/project" className="nav-link">
                    {" "}
                    <i className="far fa-folder me-2" />
                    Projects{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/template" className="nav-link">
                    {" "}
                    <i className="far fa-th-large me-2" />
                    Template{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="about" className="nav-link">
                    <i className="fal fa-suitcase me-2" />
                    About Us
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <a href="profile.html" className="nav-link">
                    <i className="fal fa-user me-2" />
                    Profile
                  </a>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-10">
      <div className="menu-right-bar text-end">
        <ul>
          <li>
            <a href="javascript:void(0);">
              <i className="fas fa-cog" />
            </a>
          </li>
          {/* <li>
            <Link to="/template" className="thm-btm">
              Create a design
            </Link>
          </li> */}

<li>
  <div className="dropdown">
    <a
      href="#"
      id="dropdownMenuButton1"
      className="thm-btm"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {" "}
      Create a design
    </a>
    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <ul className="dropdown-menu-lst design">
        <li>
          <a href="javascript:void(0);">
            <i className="far fa-file me-2" />
            Docs
          </a>
        </li>
        <li>
        <Link to="/white-board">
            <i className="far fa-chalkboard me-2" />
            WhiteBoard
          </Link>
        </li>
        <li>
          <a href="javascript:void(0);">
            <i className="fab fa-facebook-f me-2" />
            Facebook Post
          </a>
        </li>
        <li>
          <a href="javascript:void(0);">
            <i className="fal fa-globe me-2" />
            Logo
          </a>
        </li>
        <li>
          <a href="javascript:void(0);">
            <i className="fal fa-image me-2" />
            Banner
          </a>
        </li>
        <li>
          <a href="javascript:void(0);">
            <i className="fas fa-chart-bar me-2" />
            Infographic
          </a>
        </li>
        <li>
          <a href="javascript:void(0);">
            <i className="far fa-map me-2" />
            Brochure
          </a>
        </li>
      </ul>
    </div>
  </div>
</li>


          <li>
            <div className="dropdown">
              <a
                href="#"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="assets/images/user-avtar.png" alt="" />
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <ul className="dropdown-menu-lst">
                  {/* <li>
                    <a href="profile.html">Account setting</a>
                  </li> */}
                  <li className="brdr">
                    <a href="javascript:void(0);">Get help</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      Refer friends{" "}
                      <span>
                        <i className="fal fa-gift" />
                      </span>
                    </a>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy policy</Link>
                  </li>
                  <li>
                    <a href="javascript:void(0);">Get Apps</a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="modal"
                      data-bs-target="#signup"
                    >
                      Sign Up
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
  )
}
