import React from 'react'
import { useForm } from "react-hook-form";

import AuthUser from '../../AuthUser';
import { ToastContainer, toast } from 'react-toastify';

export default function Email() {
    const {http,setToken} = AuthUser();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        document.getElementById("preloader").style.display = "flex";
        const email = data.email;
    sessionStorage.setItem('email',email)
    http.post('/register-login-with-email',{
        email
    }).then(response =>{
      if(response.data.status_code == 200){
        document.getElementById("preloader").style.display = "none";
        window.$("#email-login").modal("hide");
        window.$("#otp-modal").modal("show");
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }else{
        document.getElementById("preloader").style.display = "none";
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    })
    }

  return (
    <div
  className="modal"
  id="email-login"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered email-modal">
    <div className="modal-content">
      <div className="modal-body">
        <h4 className="nav-head">Continue with email</h4>
        <p>
          We'll check if you have an account, and help create one if you don't.
        </p>
        <form  onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <label htmlFor="" className="form-label">
              Email (personal or work)
            </label>
            <input type="email" className="form-control"   {...register("email",
                            {
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })} autoComplete="off"/>
          </div>
          {errors.email && <p className="error">Please check the Email</p>}
          
          <div>
          <button type='submit'  className="sub-btn w-100">Continue</button>
            {/* <a
              href="javascript:void(0);"
              className="sub-btn w-100"
              data-bs-toggle="modal"
              data-bs-target="#otp-modal"
            >
              Continue
            </a> */}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

  )
}
