import React from 'react'
import { useForm } from "react-hook-form";
import AuthUser from '../../AuthUser';
import { useNavigate } from "react-router-dom";
import { Route, Routes, Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';

export default function Otp(){
    let navigate = useNavigate();
   const {http,setToken} = AuthUser();
   const { register, handleSubmit, formState: { errors } } = useForm();
   const onSubmit = (data) => {
    const otp = data.otp;
    const email =  sessionStorage.getItem('email');
       document.getElementById("preloader").style.display = "flex";
   http.post('/otp-verify',{
       email,otp
   }).then(response =>{
   
     if(response.data.status_code == 200){
        setToken(response.data.data.user,response.data.data.token);
       document.getElementById("preloader").style.display = "none";
       window.$("#otp-modal").modal("hide");
       toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
           return navigate("/");
         
     }else{
       document.getElementById("preloader").style.display = "none";
       toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
     }
   })
}


function ResendOtp(){
    document.getElementById("preloader").style.display = "flex";
    const email = sessionStorage.getItem('email')
    http.post('/register-login-with-email',{
    email
    }).then(response =>{
    if(response.data.status_code == 200){
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
     document.getElementById("preloader").style.display = "none";
    }else{
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      document.getElementById("preloader").style.display = "none";
    }
    })
}

  return (
    <div
  className="modal"
  id="otp-modal"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered email-modal">
    <div className="modal-content">
      <div className="modal-body">
        <h4 className="nav-head">Finish logging in</h4>
        <p>
          Once you enter the code we sent to objecttesting@gmail.com, you'll be
          all logged in.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="" className="form-label">
              Code
            </label>
            <input
              type="number"
              className="form-control"
              
              placeholder="Enter Code"
                 {...register("otp",
                            {
                                required: true,
                            })} autoComplete="off"
            />
          </div>
          {errors.otp && <p className="error">Please check the Otp</p>}
          <div className="text-end mb-3">
            <a href="javascript:void(0);" onClick={ResendOtp}>Resend Code</a>
          </div>
          <div>
            <button className="sub-btn w-100">Continue</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

  )
}
