import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Routes,
  RouterProvider,
  Link,
} from "react-router-dom";
import Auth from "./Auth";
import Guest from "./Guest";
import AuthUser from "./AuthUser";



function App() {
  const {getToken} = AuthUser();

  if(!getToken()){
    return <Guest />
  }
  return (
    //  <Guest />
     <Auth />
  );
  
}

export default App;
