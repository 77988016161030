import React from 'react'
import AuthUser from '../../AuthUser';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';

export default function User() {
  const {http} = AuthUser();
  const [userdetail,setUserdetail] = useState('');

  const {token} = AuthUser();
  
  useEffect(()=>{
    fetchUserDetail();
},[]);

const fetchUserDetail = () =>{
    http.get('/profile').then((res)=>{
        setUserdetail(res.data.data);
    });
}


  const [file, setFile] = useState()
  function onFileChangeHandler(e){
    e.preventDefault();
    setFile(URL.createObjectURL(e.target.files[0]));
    window.$('#image').attr('src',URL.createObjectURL(e.target.files[0]))
    let data = new FormData();
    data.append('profile_image', e.target.files[0]);
     http.post("/profile-image-update", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res)=>{
     
      if(res.data.status_code == 200){
         toast.success(res.data.message);
        
      }else{
        toast.error(res.data.message); 
      }
       
    })
};
 
function NameUpdate(){
  document.getElementById("preloader").style.display = "flex";
  const name = window.$('#name').val();
  http.post('/profile-update',{
   name
}).then(response =>{
  if(response.data.status_code == 200){
     
    document.getElementById("preloader").style.display = "none";
   
    toast.success(response.data.message, {
     position: toast.POSITION.TOP_RIGHT
   });
  }else{
    document.getElementById("preloader").style.display = "none";
    toast.success(response.data.message, {
     position: toast.POSITION.TOP_RIGHT
   });
  }
})

}

  return (
    <section className="main-sec">
  <div className="profile-sec">
    <div className="row">
      <div className="col-lg-8 col-md-12">
        <div className="profile-img space">
          <h3 className="heading mb-4">Your Account</h3>
          <div className="profile-img-innr">
            <div className="profile-img-innr-img">
              <span className="d-block">Profile Photo</span>
                  {userdetail.profile_image ? (
                  <img src={userdetail.profile_image} alt=""  id="image"/>
                  ) : (
                  <img src={file}  alt="" id="image"/>
                  )}

              
            </div>
            <div className="profile-img-innr-content">
              {/* <a href="javascript:void(0);" className="prfl-btn">
                Remove Photo
              </a> */}
              <label htmlFor="upload" className="prfl-btn">
                <input type="file" id="upload" name="file" onChange={onFileChangeHandler}/>
                Change Photo
              </label>
            </div>
          </div>
        </div>
        <div className="profile-name space">
          <p>
            {" "}
            <span className="block-txt">Name</span>
            <input type="text" name="name" id="name" className="form-control" value={userdetail.name}/>
          </p>
          <a href="javascript:void(0);" className="prfl-btn" onClick={NameUpdate}>
            Update
          </a>
        </div>
        <div className="profile-name space">
          <p>
            {" "}
            <span className="block-txt">Email address</span>{" "}
            {userdetail.email}
          </p>
          {/* <a href="javascript:void(0);" className="prfl-btn">
            Edit
          </a> */}
        </div>
        {/* <div className="language-select space">
          <form action="">
            <div className="row">
              <div className="col-lg-6 col-md-6 mb-3 col-sm-6">
                <label htmlFor="" className="form-label">
                  What will you be using Dixxii Sassii for?
                </label>
                <select name="" id="" className="form-select">
                  <option>
                    What will you be using Dixxii Sassii for?
                  </option>
                  <option value="">Small business</option>
                  <option value="">Student</option>
                  <option value="">Teacher</option>
                  <option value="">Large company</option>
                  <option value="">Small business</option>
                  <option value="">Student</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-6 mb-3 col-sm-6">
                <label htmlFor="" className="form-label">
                  Language
                </label>
                <select name="" id="" className="form-select">
                  <option>Select Language</option>
                  <option value="">English (US)</option>
                  <option value="">Spanish</option>
                  <option value="">French</option>
                  <option value="">Turkish</option>
                  <option value="">Tagalog</option>
                  <option value="">Spanish</option>
                  <option value="">French</option>
                </select>
              </div>
            </div>
          </form>
        </div> */}
        {/* <div className="accoutn-connect">
          <h6 className="mb-3">Connected social accounts</h6>
          <p>Services that you use to log in to Dixxii Sassii </p>
          <div className="connect-social">
            <div className="connect-social-innr">
              <div>
                <span className="social-logo">
                  <img src="assets/images/google-icon-02.png" alt="" />
                </span>
              </div>
              <div>
                <h6>Google</h6>
                <p>Design Testing</p>
              </div>
            </div>
            <div>
              <a href="javascript:void(0);" className="prfl-btn">
                Disconnect
              </a>
            </div>
          </div>
          <h4 className="nav-head mb-3">Insights</h4>
          <h6 className="mb-3">Opt out of insights collection</h6>
          <p>
            Insights give design owners information about how other people
            interact with their designs. If you would prefer your interactions
            not to be collected and included in other people’s design insights,
            you can opt out.
          </p>
          <h4 className="nav-head mb-3">Theme</h4>
          <p>
            Choose how you’d like Canva to appear. Select a theme, or sync
            themes with your system preferences.
          </p>
          <div className="web-mode">
            <ul>
              <li>
                <div>
                  <label htmlFor="dark">Dark Mode</label>
                </div>
                <div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      id="dark"
                      type="checkbox"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <label htmlFor="light">Light Mode</label>
                </div>
                <div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      id="light"
                      type="checkbox"
                      defaultChecked=""
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="web-mode web-list-view">
            <ul>
              <li>
                <div>
                  <h5 className="nav-head">Accessibility</h5>
                  <h6>Shortcuts require modifier</h6>
                  <p>
                    Single key shortcuts require the use of the Alt modifier key
                  </p>
                </div>
                <div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      id="dark"
                      type="checkbox"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <h5 className="nav-head">Link opening</h5>
                  <h6>Open links in Desktop App</h6>
                  <p>
                    Dixxii Sassii links are opened in the Desktop App instead of
                    the web browser.
                  </p>
                </div>
                <div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      id="dark"
                      type="checkbox"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
      <div className="col-lg-4 col-md-12">
        <div className="profile-sec-rgt">
          <a href="javascript:void(0);">
            <img src="assets/images/chat-icon.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}
