import React from 'react'
import { useEffect, useState } from 'react';
import AuthUser from '../AuthUser';
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery'; 
import { Link } from 'react-router-dom';
export default function Home() {
  const {http} = AuthUser();

  const [categorydetail,setCategoryDetail] = useState([]);
  const [templatedetail,setTemplateDetail] = useState([]);





useEffect(() => {
  http.get('/category-list-fixed')
    .then(res => setCategoryDetail(res.data.data))
    .catch(error => console.error(error));

    //template set
    http.get('/template-list')
    .then(res => setTemplateDetail(res.data.data))
    .catch(error => console.error(error));
}, []);


$(function(){
  $('.category').on('click',function(event){
    console.log("mohan")
    var id = $(this).data('id');
    document.getElementById("preloader").style.display = "flex";
    http.get('/template-list/'+id).then(response =>{
    if(response.data.status_code == 200){
      setTemplateDetail(response.data.data)
      document.getElementById("preloader").style.display = "none";
    }else{
      document.getElementById("preloader").style.display = "none";
      toast.success(response.data.message, {
       position: toast.POSITION.TOP_RIGHT
     });
    }
  })
  event.stopImmediatePropagation();
  })
})



const submitForm = (event) =>{
  event.preventDefault();
   const search = event.target.search.value;
   document.getElementById("preloader").style.display = "flex";
  http.post('/template-list',{
    search:search,
  }).then((res)=>{
    if(res.data.status_code == 200){
      document.getElementById("preloader").style.display = "none";
      setTemplateDetail(res.data.data)
    }else{
      document.getElementById("preloader").style.display = "none";
      toast.error(res.data.message); 
    } 
  })

}


console.log(templatedetail)

  return (
    <>
    <section className="main-sec">
      <div className="banner-sec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="banner-inner text-center">
                <h1 className="text-white text-center">
                  All the assets you need, in one place
                </h1>
                <p className="text-white text-center">
                  publishing and graphic design, Lorem ipsum is a placeholder text
                  commonly used to demonstrate the visual form of a document or a
                  typeface without
                </p>
                <form onSubmit={submitForm}>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search Content"
                    name="search"
                    id="search"
                  />
                  <button className="search-btn">
                    <i className="fas fa-search" />
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="banner-lst">
                <ul className="">
                  {/* <li>
                    <a href="javascript:void(0);" className="lst-lnk active">
                      <span className="lst-icon">
                        <img src="assets/images/banner-icon-01.png" alt="" />
                      </span>
                      <span className="lst-content">For you </span>
                    </a>
                  </li> */}
                 
                 
            

                  {categorydetail.map((data) => {
                  return (<li key={data.id}>
                    <a className="lst-lnk category" >
                      <span className="lst-icon">
                        <img src={data.category_image} alt="" className="category" data-id={data.id}/>
                      </span>
                      <span className="lst-content">{data.category_name}</span>
                    </a>
                  </li>)
                  })}
                  {/* <li>
                    <a href="javascript:void(0);" className="lst-lnk">
                      <span className="lst-icon">
                        <img src="assets/images/banner-icon-07.png" alt="" />
                      </span>
                      <span className="lst-content">More</span>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="post-card pdng">
        <div className="container-fluid">
          <h3 className="heading">You might want to try...</h3>
          <div className="owl-carousel owl-theme" id="post-card-slider">
            <div className="post-card-inner">
              <div className="post-card-inner-img">
                <img src="assets/images/post-card-img-01.png" alt="" />
                <div className="post-card-content">
                  <div className="post-card-content-innr">
                    <a href="javascript:void(0);">Create custom designs</a>
                  </div>
                </div>
              </div>
              <h6>Vector art</h6>
            </div>
            <div className="post-card-inner">
              <div className="post-card-inner-img">
                <img src="assets/images/post-card-img-02.png" alt="" />
                <div className="post-card-content">
                  <div className="post-card-content-innr">
                    <a href="javascript:void(0);">Create custom designs</a>
                  </div>
                </div>
              </div>
              <h6>Facebook cover</h6>
            </div>
            <div className="post-card-inner">
              <div className="post-card-inner-img">
                <img src="assets/images/post-card-img-03.png" alt="" />
                <div className="post-card-content">
                  <div className="post-card-content-innr">
                    <a href="javascript:void(0);">Create custom designs</a>
                  </div>
                </div>
              </div>
              <h6>Add</h6>
            </div>
            <div className="post-card-inner">
              <div className="post-card-inner-img">
                <img src="assets/images/post-card-img-04.png" alt="" />
                <div className="post-card-content">
                  <div className="post-card-content-innr">
                    <a href="javascript:void(0);">Create custom designs</a>
                  </div>
                </div>
              </div>
              <h6>Card Design</h6>
            </div>
            <div className="post-card-inner">
              <div className="post-card-inner-img">
                <img src="assets/images/post-card-img-05.png" alt="" />
                <div className="post-card-content">
                  <div className="post-card-content-innr">
                    <a href="javascript:void(0);">Create custom designs</a>
                  </div>
                </div>
              </div>
              <h6>Card Design</h6>
            </div>
            <div className="post-card-inner">
              <div className="post-card-inner-img">
                <img src="assets/images/post-card-img-04.png" alt="" />
                <div className="post-card-content">
                  <div className="post-card-content-innr">
                    <a href="javascript:void(0);">Create custom designs</a>
                  </div>
                </div>
              </div>
              <h6>Post Design</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="post-card pdng">
        <div className="container-fluid">
          <h3 className="heading">Recent Design</h3>
          <div className="row">
           
          {templatedetail.map((temp) => {
             return (<div key={temp.id} className="col-lg-2 col-md-3 col-sm-4 col-4">
              <div className="post-card-inner recent-card">
                <div className="post-card-inner-img">
                  <img src={temp.template_image} alt="" />
                  <div className="post-card-content">
                    <div className="post-card-content-innr">
                      <Link to="/template">{temp.title}</Link>
                    </div>
                  </div>
                </div>
                <h6>{temp.title}</h6>
              </div>
            </div>)
          })}
          
            
          </div>
        </div>
      </div>
    </section>
  </>
  )
}
