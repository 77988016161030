import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react';
import AuthUser from '../../AuthUser';
import $ from 'jquery'; 

export default function Sidebar() {
  var pathname = window.location.pathname;

  const {http} = AuthUser();
  const [categorydetail,setCategoryDetail] = useState([]);

  const [userdetail,setUserdetail] = useState('');
  const {token,logout,user} = AuthUser();
  const logoutUser = () => {
    if(token != undefined){
         logout();
    }
}
 
useEffect(()=>{
  fetchUserDetail();
},[]);

const fetchUserDetail = () =>{
  if(token != null){
    http.get('/profile').then((res)=>{
      setUserdetail(res.data.data);
  });
  }
}



useEffect(() => {
  http.get('/category-list')
    .then(res => setCategoryDetail(res.data.data))
    .catch(error => console.error(error));
}, []);


  $('.click-menu').click(function(){
    $('.has-submenu').removeClass('Menushow');
    var a = $(this).parent().toggleClass('Menushow');
  })


  $('.bx-menu').click(function(){
    $('body').toggleClass('resize-menu');
});





  return (
    <div className={`button ${pathname === '/white-board' ? 'sidebar white-boarbard':'sidebar'}`}>
    <div className="logo-details">
        <Link to="/">
        <i className="bx bxl-c-plus-plus" />
        <span className="logo_name">Dixxi Sixxi 
        </span>
        </Link>
    </div>
    <ul className="navigation-menu">
      <li className="nav-item">
        <Link to="/" className="nav-link" >
          <i className="fal fa-home link_icn" />
          <span className="link_name">Home</span>{" "}
        </Link>
      </li>
      <li className="nav-item has-submenu">
        <a className="nav-link click-menu" href="javascript:void(0);">
          {" "}
          <i className="fal fa-th-list link_icn" />
          <span className="link_name"> Templates </span>
        </a>
        <ul className="submenu">
          {categorydetail.map(data => {
          return <li className="nav-item dropdown" key={data.id}>
            {data.child_category.length > 0 &&
            <a
              className="nav-link dropdown-toggle"
              href="javascript:void(0);"
              data-bs-toggle="dropdown"
            >
              {data.category_name}
            </a>
          }
          {data.child_category.length < 1 &&
                <a
                className="nav-link"
                href="javascript:void(0);"
              >
                {data.category_name}
              </a>
           }
            <ul className="dropdown-menu dropdown-menu-innr">
            {data.child_category.map(value=>{
              return<li key={value.id}>
                <a className="dropdown-item">{value.category_name}</a>
              </li>
              })}
            </ul>
          </li>
           })}
        </ul>
      </li>
      <li className="nav-item has-submenu">
        <a className="nav-link click-menu" href="javascript:void(0);">
          {" "}
          <i className="fal fa-th-list link_icn" />
          <span className="link_name">Matrix</span>
        </a>
        <ul className="submenu">
          <li>
            <a className="nav-link" href="#">
              Flipbooks
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              Diaries
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              Architectural Design Studio
            </a>{" "}
          </li>
          <li>
            <a className="nav-link" href="#">
              Photo Integration
            </a>{" "}
          </li>
          <li>
            <a className="nav-link" href="#">
              Dixxii Sassii PDF Creation
            </a>{" "}
          </li>
          <li>
            <a className="nav-link" href="#">
              Paint Studio
            </a>{" "}
          </li>
          <li>
            <a className="nav-link" href="#">
              Mechanical Studio
            </a>{" "}
          </li>
          <li>
            <a className="nav-link" href="#">
              My Pet Academy
            </a>{" "}
          </li>
          <li>
            <a className="nav-link" href="#">
              Manual Conservatory
            </a>{" "}
          </li>
          <li>
            <a className="nav-link" href="#">
              A World of Stars
            </a>{" "}
          </li>
          <li>
            <a className="nav-link" href="#">
              Voice Over
            </a>{" "}
          </li>
          <li>
            <a className="nav-link" href="#">
              The Dark Realm
            </a>{" "}
          </li>
        </ul>
      </li>
      <li className="nav-item has-submenu">
        <a className="nav-link click-menu" href="javascript:void(0);">
          {" "}
          <i className="fal fa-grip-horizontal link_icn" />
          <span className="link_name">Orion</span>
        </a>
        <ul className="submenu">
          <li>
            <a className="nav-link" href="#">
              Spirograph
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              Text
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              Imagery
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              Impressions
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              Technical
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              Skyzone
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              Creation
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              Blueprints
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              Communication
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              Connexxion
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">
          <i className="fal fa-video link_icn" />
          <span className="link_name">Video </span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">
          <i className="fal fa-folder link_icn" />
          <span className="link_name">Folder</span>{" "}
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">
          <i className="fal fa-swatchbook link_icn" />
          <span className="link_name">
            Brand Hub <i className="fas fa-crown crown ms-5" />
          </span>{" "}
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">
          <i className="fal fa-users link_icn" />
          <span className="link_name">Create Team</span>{" "}
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">
          <i className="fal fa-trash link_icn" />
          <span className="link_name">Trash</span>{" "}
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">
          <i className="fal fa-cog fa-spin link_icn" />
          <span className="link_name">Setting</span>{" "}
        </a>
      </li>
      {token != null &&
        <li>
          <div className="profile-details">
            <div className="profile-content">
            {userdetail.profile_image ? (
              <img src={userdetail.profile_image} alt=""  id="image"/>
              ) : (
                <img src="assets/images/user-img.png" alt="Profile" />
            )}
            </div>
            <div className="name-job">
              <div className="profile_name">{userdetail.name}</div>
              <div className="job">Desginer</div>
            </div>
            <a href="javascript:void(0);" onClick={logoutUser}>
              <i className="bx bx-log-out" />
            </a>
          </div>
        </li>
       }
    </ul>


    {/* {pathname == '/white-board' : */}
    {pathname === '/white-board' &&
    <div className="white-board-menu-innr">
  <div className="menu-search-bar">
    <form action="">
      <div className="input-group">
        <span className="input-group-text">
          <i className="fas fa-search" />
        </span>
        <input type="search" className="form-control" />
        <button className="input-group-text">
          <i className="fas fa-filter" />
        </button>
      </div>
    </form>
  </div>
  <div className="owl-carousel owl-theme button-slider-main" id="button-slider">
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Posts
      </a>
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Business
      </a>{" "}
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Cards
      </a>
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Posts
      </a>
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Templates
      </a>
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Posts
      </a>
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Cards
      </a>
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Social Media
      </a>
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        YouTube
      </a>
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Facebook
      </a>
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Twitter
      </a>
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Instagram
      </a>
    </div>
    <div className="button-slider-innr">
      <a href="javascript:void(0);" className="slider-lnk">
        Instagram
      </a>
    </div>
  </div>
  <h5 className="white-board-menu-heading">
    <span>Recently used</span>
    <a href="javascript:void(0);">See all</a>
  </h5>
  <div
    className="owl-carousel owl-theme menu-post-slider-main"
    id="menu-post-slider"
  >
    <div className="menu-post-slider-innr">
      <a href="javascript:void(0);">
        <img src="assets/images/post-img-01.png" alt="" />
      </a>
    </div>
    <div className="menu-post-slider-innr">
      <a href="javascript:void(0);">
        <img src="assets/images/post-img-02.png" alt="" />
      </a>
    </div>
  </div>
  <h5 className="white-board-menu-heading">
    <span>All results</span>
  </h5>
  <div className="row g-2 ">
    <div className="col-lg-12 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-01.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-02.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-03.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-04.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-05.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-06.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-07.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-08.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-09.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-10.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-11.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
      <a href="javascript:void(0);">
        <img
          src="assets/images/result-img-12.png"
          alt=""
          className="img-fluid mb-2 w-100"
        />
      </a>
    </div>
  </div>
</div>
}


  </div>

  )
}
