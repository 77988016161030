import React from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Project() {
  return (
    <section className="main-sec">
  <div className="project-bar space">
    <div className="container-fluid">
      <h3 className="heading">Projects</h3>
      <div className="row">
        <div className="col-lg-5 col-md-6">
          <div className="project-bar-lft-frm">
            <form action="" className="row">
              <div className="input-group col-lg-4 col-md-6 mb-3">
                <span className="input-group-text">
                  <i className="far fa-user-circle" />
                </span>
                <select className="form-select">
                  <option selected="">Choose..</option>
                  <option value="">Any owner</option>
                  <option value="">Personal</option>
                  <option value="">Team</option>
                  <option value="">Group</option>
                </select>
              </div>
              <div className="input-group col-lg-4 col-md-6 mb-3">
                <span className="input-group-text">
                  <i className="far fa-calendar-alt" />
                </span>
                <select className="form-select">
                  <option selected="">Any Time</option>
                  <option value="">10 Days</option>
                  <option value="">Last Week</option>
                  <option value="">Last Months</option>
                  <option value="">Last 3 Months</option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-2 d-lg-block d-none" />
        <div className="col-lg-5 col-md-6 text-end">
          <div className="project-bar-rgt-frm">
            <form action="" className="row">
              <div className="col-lg-9 col-md-6 d-flex mb-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search Content"
                />
                <button className="search-btn">
                  <i className="fas fa-search" />
                </button>
              </div>
              <div className="col-lg-3 col-md-6 mb-3">
                <a
                  href="javascript:void(0);"
                  className="prfl-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#add-folder"
                >
                  <i className="far fa-folder-plus me-2" />
                  Add Folder
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="post-card recent-card-main pdng">
    <div className="container-fluid">
      <h3 className="heading">Recent Design</h3>
      <div className="row">
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-01.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Card designs</a>
                </div>
              </div>
            </div>
            <h6>Card Design</h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-02.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Logo Design</a>
                </div>
              </div>
            </div>
            <h6>Logo Design</h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-03.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Text Effect Design</a>
                </div>
              </div>
            </div>
            <h6>Text Effect Design</h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-04.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Vector</a>
                </div>
              </div>
            </div>
            <h6>Vector</h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-05.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Motivational quotes</a>
                </div>
              </div>
            </div>
            <h6>Motivational quotes</h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-06.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Post Design</a>
                </div>
              </div>
            </div>
            <h6>Post Design</h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-06.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Post Design</a>
                </div>
              </div>
            </div>
            <h6>Post Design</h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-05.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Motivational quotes</a>
                </div>
              </div>
            </div>
            <h6>Motivational quotes</h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-04.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Vector</a>
                </div>
              </div>
            </div>
            <h6>Vector</h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-03.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Text Effect Design</a>
                </div>
              </div>
            </div>
            <h6>Text Effect Design</h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-02.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Logo Design</a>
                </div>
              </div>
            </div>
            <h6>Logo Design</h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-4">
          <div className="post-card-inner recent-card">
            <div className="post-card-inner-img">
              <img src="assets/images/recent-img-01.png" alt="" />
              <div className="post-card-content">
                <div className="post-card-content-innr">
                  <a href="javascript:void(0);">Card designs</a>
                </div>
              </div>
            </div>
            <h6>Card Design</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="folder-sec space">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="folder-innr">
            <div className="accordion">
              <div className="accordion-item">
                <a
                  href="#"
                  className="accordion-button"
                  data-bs-toggle="collapse"
                  data-bs-target="#folder-01"
                >
                  <i className="fal fa-chevron-right me-2" />
                  Folders
                </a>
                <div id="folder-01" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <a href="javascript:void(0);" className="prfl-btn">
                          <i className="fas fa-download me-2" />
                          Download
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" className="prfl-btn">
                          <i className="fas fa-folders me-2" />
                          Wedding Image
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" className="prfl-btn">
                          <i className="fas fa-folders me-2" />
                          Website
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" className="prfl-btn">
                          <i className="fas fa-folders me-2" />
                          UI Design
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" className="prfl-btn">
                          <i className="fas fa-folders me-2" />
                          Template
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <a
                  href="#"
                  className="accordion-button"
                  data-bs-toggle="collapse"
                  data-bs-target="#folder-02"
                >
                  <i className="fal fa-chevron-right me-2" />
                  Images
                </a>
                <div id="folder-02" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <div className="post-card">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <div className="post-card-inner recent-card">
                              <div className="post-card-inner-img">
                                <img
                                  src="assets/images/recent-img-01.png"
                                  alt=""
                                />
                                <div className="post-card-content">
                                  <div className="post-card-content-innr">
                                    <a href="javascript:void(0);">
                                      Card designs
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <h6>Card Design</h6>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <div className="post-card-inner recent-card">
                              <div className="post-card-inner-img">
                                <img
                                  src="assets/images/recent-img-02.png"
                                  alt=""
                                />
                                <div className="post-card-content">
                                  <div className="post-card-content-innr">
                                    <a href="javascript:void(0);">
                                      Logo Design
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <h6>Logo Design</h6>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <div className="post-card-inner recent-card">
                              <div className="post-card-inner-img">
                                <img
                                  src="assets/images/recent-img-03.png"
                                  alt=""
                                />
                                <div className="post-card-content">
                                  <div className="post-card-content-innr">
                                    <a href="javascript:void(0);">
                                      Text Effect Design
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <h6>Text Effect Design</h6>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <div className="post-card-inner recent-card">
                              <div className="post-card-inner-img">
                                <img
                                  src="assets/images/recent-img-04.png"
                                  alt=""
                                />
                                <div className="post-card-content">
                                  <div className="post-card-content-innr">
                                    <a href="javascript:void(0);">Vector</a>
                                  </div>
                                </div>
                              </div>
                              <h6>Vector</h6>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <div className="post-card-inner recent-card">
                              <div className="post-card-inner-img">
                                <img
                                  src="assets/images/recent-img-05.png"
                                  alt=""
                                />
                                <div className="post-card-content">
                                  <div className="post-card-content-innr">
                                    <a href="javascript:void(0);">
                                      Motivational quotes
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <h6>Motivational quotes</h6>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-3 col-sm-4 col-4">
                            <div className="post-card-inner recent-card">
                              <div className="post-card-inner-img">
                                <img
                                  src="assets/images/recent-img-06.png"
                                  alt=""
                                />
                                <div className="post-card-content">
                                  <div className="post-card-content-innr">
                                    <a href="javascript:void(0);">
                                      Post Design
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <h6>Post Design</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}
