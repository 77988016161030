import React from 'react'

export default function Login() {
  return (
    <div
    className="modal fade"
    id="signup"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered modal-lg signup-modal">
      <div className="modal-content">
        <div className="modal-body">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6 d-lg-block d-none">
              <div className="signup-modal-img">
                <img src="/assets/images/modal-signup-img.png" alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="signup-modal-content">
                <a href="javascript:void(0);" className="brand">
                  <img src="/assets/images/logo.png" alt="" />
                </a>
                <h4>Log in or sign up in seconds</h4>
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      <img
                        src="/assets/images/google-icon.png"
                        alt=""
                        className="me-2"
                      />
                      Continue with Google
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <img
                        src="/assets/images/facebook-icon.png"
                        alt=""
                        className="me-2"
                      />
                      Continue with Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="modal"
                      data-bs-target="#email-login"
                    >
                      <img
                        src="/assets/images/email-icon.png"
                        alt=""
                        className="me-2"
                      />
                      Continue with Email
                    </a>
                  </li>
                </ul>
                <p className="text-center">
                  By continuing, you agree to Dixxii Sassii{" "}
                  <a href="javascript:void(0);">Terms of Use</a>. Read our{" "}
                  <a href="javascript:void(0);">Privacy Policy</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}
